import React, { useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
// import './dashboard.css';

const BarChart = ({course_status}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const barWidth = 40;

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const customTooltipRenderer = (context) => {
    const {
      chart,
      tooltip
    } = context;
    const tooltipEl = chart.canvas.parentNode.querySelector('.custom-tooltip');

    if (!tooltipEl) {
      return;
    }

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    const position = chart.canvas.getBoundingClientRect();

    tooltipEl.innerHTML = `
      <div class="tooltip-content">
        <div class="p-3">
          ${tooltip.body.map((bodyLine) => `<p class="mb-1">${bodyLine.lines.join(': ')}</p>`).join('')}
          <p class="mb-1">${tooltip.title[0]}</p>
        </div>
        <a href="#" class="text-decoration-none" onclick={openModal}>Click on column for action</a>
      </div>
    `;

    const tooltipWidth = tooltipEl.clientWidth;
    const tooltipHeight = tooltipEl.clientHeight;

    let left = position.left + window.pageXOffset + tooltip.caretX - tooltipWidth / 1.2;
    let top = position.top + window.pageYOffset + tooltip.caretY - tooltipHeight - 450;

    left = Math.min(Math.max(left, 0), document.body.clientWidth - tooltipWidth);
    top = Math.min(Math.max(top, 0), document.body.clientHeight - tooltipHeight);

    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = `${left}px`;
    tooltipEl.style.top = `${top}px`;

  };

  const labels = ['Licenses Bought', 'Invitation Sent', 'Invite Accepted', 'In Progress', 'Course Completed', 'Deactivated', 'Expired Licenses'];
  const completedData = [
    parseInt(course_status.total_assigned),
    parseInt(course_status.total_used),
    parseInt(course_status.accepted),
    parseInt(course_status.inprogress),
    parseInt(course_status.completed),
    parseInt(course_status.deactivate),
    parseInt(course_status.total_expired)
  ];

  const remainingAssigned = 0;
  const remainingInviteSent = parseInt(course_status.total_assigned) - parseInt(course_status.total_used);
  const remainingInviteAccepted = parseInt(course_status.total_used) - parseInt(course_status.accepted);
  const remainingInProgress = parseInt(course_status.notstarted);//parseInt(course_status.accepted) - parseInt(course_status.inprogress);
  const remainingCompleted = parseInt(course_status.completed) == 0 ? parseInt(course_status.inprogress) : 0 ;

  const remainingData = [
    remainingAssigned,
    remainingInviteSent,
    remainingInviteAccepted,
    remainingInProgress,
    remainingCompleted
  ];

  // if ((parseInt(course_status.accepted) - parseInt(course_status.completed))== 0) {
  //   remainingData[3] = parseInt(course_status.accepted) - parseInt(course_status.inprogress);
  // }

  const datasets = [{
      label: 'Count',
      backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298','#6A4C93', 'rgb(37, 150, 190)'],
      borderColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298', '#6A4C93', 'rgb(37, 150, 190)'],
      data: completedData,
      barThickness: barWidth,
      stack: 'stack2',
      borderWidth: 0.5,
      categoryPercentage: 0.85, // Adjust this value for spacing
      barPercentage: 0.95, // Adjust this value for spacing
    },
    {
      label: 'Remaining',
      backgroundColor: ['#146B90', '#F2D7D7', 'rgba(244, 181, 50, 0.4)', 'rgba(245, 147, 144, 0.4)', 'rgba(34, 162, 152, 0.4)'],
      borderColor: ['#146B90', '#F2D7D7', 'rgba(244, 181, 50, 0.4)', 'rgba(245, 147, 144, 0.4)', 'rgba(34, 162, 152, 0.4)'],
      data: remainingData,
      barThickness: barWidth,
      stack: 'stack2',
      borderWidth: 0.5,
      categoryPercentage: 0.85, // Adjust this value for spacing
      barPercentage: 0.95, // Adjust this value for spacing
    }
  ];
  // };
  const data = {
    labels: labels,
    datasets: datasets,
  };
  console.log(data);

  const options = {
    indexAxis: 'y',
    responsive: true,
    hover: {
      animationDuration: 1
    },
    animation:{
      duration: 0,
      onComplete: function () {
        let myChart = this;
        let chartArea = myChart.chartArea;
        let { left, top } = chartArea;
        const datasets = myChart.data.datasets;
        for (let datasetIndex = 0; datasetIndex < datasets.length; datasetIndex++) {
          const meta = myChart.getDatasetMeta(datasetIndex);
          const data = datasets[datasetIndex].data;
  
          for (let index = 0; index < data.length; index++) {
            let bar = meta.data[index];
            let value = data[index];
            if (value != 0) {
              let xPos = bar.x - barWidth;
              let yPos = bar.y; // Adjust yPos for horizontal bar chart
              
              if (xPos < left) {
                xPos = left + 6;
              }

              var ctx = myChart.ctx;
              ctx.save();
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = 'black';
              ctx.font = 'bold 12px Arial';
              ctx.fillText(value, xPos, yPos);
              ctx.restore();
            } 
          }
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        position: 'nearest',
        // external: customTooltipRenderer,
      },
      legend: {
        display: false,
        position: 'bottom',
        borderRadius: 5
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: true,
        },
      },
      y: {
        display: true,
        grid: {
          display: false,
        },
      }
    },
  };

  return (
    <div className='statistics mt-5 p-5'>
      <h3 className='fw-bold font-22 mb-4'>Statistics</h3>
      <Bar type="bar" data={data} options={options} />
      <div className="custom-tooltip"></div>

      {isModalOpen && (
        <div className="modal">
         
          <button onClick={closeModal}>Close Modal</button>
        </div>
      )}
    </div>
  );
};

export default BarChart;
