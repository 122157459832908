import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CustomSelect from '../customSelect/customSelect';
import Modal from 'react-modal';
import apiService from '../../../services/apiService';
import { Link, useNavigate} from 'react-router-dom';
import Loader from '../../layouts/loader';
import CustomToast from '../../Toast';

const Orgnisation = () => {
  const [courseLicenseList, setCourseLicenseList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [filterOption, setFilterOption] = useState('all');
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numOfLicenses, setNumOfLicenses] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownCourseList, setDropdownCourseList] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState('')
  const [sortOrder, setSortOrder] = useState('asc');
  const [totalCount, setTotalCount] = useState(null);
  const [selectedItem, setSelectedItem] = useState({})
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    setCourseLicenseList([])
    setSearchTerm(e.target.value);
    setCurrentPage(1)
  };

  const openModal = (data) => {
    setSelectedItem(data)
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    fetchDropdownCourseList();
    let userDetail = sessionStorage.getItem("userDetail")
    userDetail = JSON.parse(userDetail)
    fetchCourseLicenses();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, searchTerm, selectedCourseId, filterOption, totalCount]);

  const fetchCourseLicenses = () => {
    setLoading(true);
    setLoader(true);
    if(selectedCourseId == "clear") {
      setSelectedCourseId('');
    }
    const token = apiService.getAuthToken();
    apiService.getv1(`get-org-info?page=${currentPage}&itemsPerPage=50&search=${searchTerm}&course=${selectedCourseId}&filter=${filterOption}&sort=${sortOrder || ''}`, token)
      .then(
        (response) => {
          setCourseLicenseList((prevData) => {
            const uniqueOrgs = response?.data?.orgnizations.filter(
              (org) => !prevData.some((prevOrg) => prevOrg.org_id === org.org_id)
            ) || [];
            return [...prevData, ...uniqueOrgs];
          });
          setTotalCount(response?.data?.count || 0);
          setLoading(false);
          setLoader(false);
        },
        (error) => {
          console.error("Error fetching data", error);
          setLoading(false);
          setLoader(false);
          // CustomToast.error("Error occured while fetching org list data")
        }
      );
  };
  
  const fetchDropdownCourseList = () => {
    setLoading(true);
    setLoader(true);
    const token = apiService.getAuthToken()
    apiService.getv1Cms(`dropdownCourseList`, token).then(
      (response) => {
        setDropdownCourseList(response?.data);
        setLoading(false);
        setLoader(false);
      },
      (error) => {
        setLoading(false);
        setLoader(false);
        console.error("Error fetching data", error);
      }
    );
  };
  

  const handleScroll = () => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    const scrollTrigger = 0.9;

    if (scrollTop + clientHeight >= scrollHeight * scrollTrigger && !loading && totalCount >= currentPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleCourseSelect = (selectedOptions) => {
    const commaSeparatedValues = selectedOptions.map(item => item.value).join(',');
    setCourseLicenseList([])
    setSelectedCourseId(commaSeparatedValues)

    const allCoursesOption = selectedOptions.find(option => option.value === 'clear');
    console.log("allCoursesOption", allCoursesOption);
    if(allCoursesOption){
      setSelectedCourses([]);
    }else{
      setSelectedCourses(selectedOptions);
    }
    setCurrentPage(1)
  };

  const handleFilterChange = (event) => {
    setCourseLicenseList([])
    setFilterOption(event.target.value);
    setCurrentPage(1)
  };

  const clearFilter = () => {
    setFilterOption('all');
    setSelectedCourseId('')
    setSelectedCourses([]);
    fetchCourseLicenses();
    setShowFilterDropdown(false)
    setCurrentPage(1)
  };

  const options = [
    { value: 'select_all', label: 'Select All' },
    ...dropdownCourseList.map((option) => ({
      value: option.course_id,
      label: option.name || option.course_title,
    })),
    { value: 'clear', label: 'Clear' },
  ];

  const handleChange = (selectedOptions) => {
    const lastOption = selectedOptions[selectedOptions.length - 1];
    console.log("selectedOptions", selectedOptions);
    if (selectedOptions && selectedOptions.length) {
     
      const allCoursesOption = selectedOptions.find(option => option.value === 'select_all');
      
      if (allCoursesOption) {
        setSelectedCourses([]);
        setSelectAllChecked(true);
      } else {
        handleCourseSelect(selectedOptions);
        setSelectAllChecked(false);
      }
    } else if (lastOption && lastOption.value === 'clear') {
        handleCourseSelect([]);
        setSelectAllChecked(false);
    }
    else{
      handleCourseSelect([]);
      setSelectAllChecked(false);
    }
    
  };
  
  const handleSort = () => {
    // Toggle the sorting order between 'asc' and 'desc'
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
  
    // Sort the courseLicenseList based on the org_name and sorting order
    setCourseLicenseList((prevData) => {
      return [...prevData].sort((a, b) => {
        const nameA = a.org_name.toUpperCase()?.trim();
        const nameB = b.org_name.toUpperCase()?.trim();
  
        if (newSortOrder === 'asc') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    });
  };

  const confirm = ()=>{
    const token = apiService.getAuthToken();
    let orgId = localStorage.getItem("ORGID")
    let userDetail = sessionStorage.getItem("userDetail")
      userDetail = JSON.parse(userDetail)
    console.log(selectedItem);
    let data ={
      "org_course_id" : selectedItem?.org_course_id,
      "org_id" :orgId,
      "number_of_revoke":numOfLicenses
    }
    apiService.postV1('revoke-multiple',data,token).then((res)=>{
      if(res && res.status == 'success') {
        CustomToast.success("License revoke request sent succeessfully")
        closeModal()
        navigate('/organisation')
      }
    }).catch((err)=> {
      console.log(err)
      CustomToast.error(err.message)
      closeModal()
      navigate('/organisation')
    })
    }

    // const inviteUser = (ele) => {
    //   if(courseData && courseData.length >  0 ) {
    //       localStorage.setItem("selectedCourse", JSON.stringify(courseData[0].course_status))
    //       localStorage.setItem('selectedCourseObj', JSON.stringify(courseData[0].course_status[0]));
    //       localStorage.setItem("ORGID", courseData[0].org_id)
    //       const orgData = {
    //           org_id: courseData[0].org_id,
    //           org_name: courseData[0].org_name
    //       }
    //       localStorage.setItem("orgData", JSON.stringify(orgData))
    //       navigate("/course_invite")
    //   } else {
    //       navigate("/course_invite")
    //   }
    // }

  return (
    <div className='page__main'>
      <div className="dashboard" style={{minHeight: '100vh'}}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4 className="fw-bold mb-0">Organisation </h4>
          <a href="/create_organisation" className="d-flex justify-content-between align-items-center text-decoration-none mb-0">
            <img src="/images/account-plus.png" className="edit_btn"></img>
            <span className="fw-bold primary_color font-20">Create Organisation</span>
          </a>
        </div>

        <div className="d-flex justify-content-between align-items-center mb-2">

          <div className="form-group has-search mt-4">
            <span className="form-control-feedback"><img src="/images/search.svg" /></span>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
          <button onClick={() => setShowFilterDropdown(!showFilterDropdown)} className="filter-btn btn btn-link d-flex justify-content-between align-items-center text-decoration-none mb-0 position-relative">
            <img src="/images/filter.svg" className="edit_btn"></img>
            <span className="fw-bold primary_color font-20">Filter</span>


            {showFilterDropdown && (
              <div className="filter-dropdown d-flex flex-column">
                <button className='btn btn-link text-decoration-none align-self-end mb-2' onClick={clearFilter}>Clear Filter</button>
                <div className="filter-radio">
                  <label>
                    <input
                      type="radio"
                      value="expired"
                      className='text-black'
                      checked={filterOption === 'expired'}
                      onChange={handleFilterChange}
                    />
                    Expired Courses
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="expiringIn30Days"
                      className='text-black'
                      checked={filterOption === 'expiringIn30Days'}
                      onChange={handleFilterChange}
                    />
                    Expiring in 30 days
                  </label>
                </div>
              </div>
            )}
          </button>
        </div>


        <div className='mb-4'>
          <Select
            className='basic-multi-select w-25'
            placeholder="Select Course Name"
            options={options}
            value={selectedCourses}
            onChange={handleChange}
            isMulti
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            styles={{
              option: (provided, state) => ({
                ...provided,
                borderBottom: '1px solid #ccc',
                color: state.isSelected ? 'white' : 'black',
                backgroundColor: state.isSelected ? '#007bff' : 'white',
                padding: 10,
              }),
              control: (provided) => ({
                ...provided,
                border: '1px solid #ddd',
              }),
            }}
            formatOptionLabel={(option) => ( <>
              
          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              {option.value === 'select_all' && (
            <input
              type="checkbox"
              checked={selectAllChecked || selectedCourses.some((course) => course.value === option.value)}
              onChange={() => {}}
              style={{ marginRight: '10px' }}
            />
          )}
          
                {option.value !== 'select_all' && option.value !== 'clear' && (
                  <input
                    type="checkbox"
                    checked={selectAllChecked || selectedCourses.some((course) => course.value === option.value)}
                    onChange={() => {}}
                    style={{ marginRight: '10px' }}
                  />
                )}
                {option.label}
              </div>
              </> )}
          />
        </div>
        <table className="table table-striped table-responsive org-admin">
          <thead className="thead-dark">
            <tr>
              <th className="text-center">S. No.</th>
               <th>
                  <span onClick={handleSort} className='d-flex justify-content-between align-items-center'>Company Name <img src='/images/sort.svg' alt='sort' className='me-5' width={20} height={20} /></span>              
               </th>
              <th>Course Name</th>
              <th>Licences Assigned</th>
              <th>Licences Used</th>
              <th></th>
            </tr>
          </thead>
        {loader ? <Loader />
        :   <tbody>
        {courseLicenseList && courseLicenseList?.map((element, i) => (
          <tr key={element.org_id}>
            <td valign='middle' className="text-center">{i + 1}</td>
            <td valign='middle'><a href={`/organisation_details/${element.org_id}`} className='text-black text-decoration-none'>{element.org_name}</a></td>
            <td>
              {element.course_licenses?.map((ele, j) => (
                <span key={ele.course_id} className='text-capitalize'>
                  {ele.course_title}
                  {j !== element.course_licenses.length - 1 && <br />}
                </span>
              ))}
            </td>
            <td>
              {element.course_licenses?.map((ele, j) => (
                <span key={ele.course_id}>
                  {ele.total_assigned}
                  {j !== element.course_licenses.length - 1 && <br />}
                </span>
              ))}
            </td>
            <td>
              {element.course_licenses?.map((ele, j) => (
                <span key={ele.course_id}>
                  {ele.total_used}
                  {j !== element.course_licenses.length - 1 && <br />}
                </span>
              ))}
            </td>
            <td>
              <div className='d-flex flex-column'>
                
                  {element.course_licenses?.map((ele, j) => (
                    <div key={ele.course_id} className="dropdown organisations my-2">
                    <img
                      src="/images/dots-vertical.png"
                      className="dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      alt={`dots-vertical`}
                    />
                    {/*j !== element.course_licenses.length - 1 && <br />*/}
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" 
                      // to={`/assign_new_course`} 
                      // state={{ data: element }}
                      to="/assign_new_course"
                      state={{element}}
                      > 
                      Add Licences </Link>
                      <button onClick={()=>openModal(ele)} className="dropdown-item">Revoke Licences</button>
                    </div>
                    </div>
                  ))}
               
              </div>
              
              <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Modal"
                className='Modal revoke'
              >
                <div className='d-flex flex-column justify-content-center align-items-center text-left p-4 card w-75'>
                  <button type="button" class="close text-white" onClick={closeModal}> <svg width="17" height="15" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90"></rect><path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white"></path></svg></button>
                  <h3 className='fw-bold font-26 mb-4'>Revoke Licences</h3>
                  <div className='text-center mb-4'>
                    <p className='mb-0'>Please enter the quantity under to revoke. </p>
                  </div>
                  <form className='w-100'>
                    <label className='w-100'>
                      Enter No. Of Licenses:
                      <input
                        type="text"
                        value={numOfLicenses}
                        className='form-control mt-2 mb-4 w-100'
                        placeholder='No. Of Licenses'
                        onChange={(e) => setNumOfLicenses(e.target.value)}
                      />
                    </label>
                    <button type="button"  onClick={confirm} className='m-auto btn btn-md w-100 btn-primary'>Confirm </button>
                  </form>
                </div>
              </Modal>
            </td>
          </tr>
        ))}
      </tbody>}
        </table>

        {/* Loading indicator */}
        {loading && <p>Loading...</p>}
      </div>
    </div>
  );
};

export default Orgnisation;

