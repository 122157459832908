import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './dashboard.css';
import apiService from '../../../services/apiService';
import moment from 'moment';
import { useLocation, Link } from 'react-router-dom';
import CustomToast from '../../Toast';
import {CSVLink} from 'react-csv';

const LicenseSoldUsed = ({ showDetails = true, soldUsedLicensesProps, selectedOrgId }) => {
    const location = useLocation();
    soldUsedLicensesProps = location.state?.soldUsedLicensesProps || soldUsedLicensesProps;
    selectedOrgId = location.state?.selectedOrgId || selectedOrgId;
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [orgInfo, setOrgInfo] = useState()
    const [courseOptions, setCourseOptions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [monthlyDataSold, setMonthlyDataSold] = useState(Array(12).fill(0))
    const [monthlyDataUsed, setMonthlyDataUsed] = useState(Array(12).fill(0))
    const [sul,setSUL]=useState()
    const [data, setData] = useState();
    const barWidth = 30;
    const [dataToDownload, setDataToDownload] = useState([]);
    const columns = [ // Define column names
        "S. No.",
        "Month",
        "Sold",
        "Used"
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
        
        if(soldUsedLicensesProps && Array.isArray(soldUsedLicensesProps)) {
           setSUL(soldUsedLicensesProps)
        } else {
            setSUL([])
        }
        setDatas(soldUsedLicensesProps)
        setOrgInfo(selectedOrgId)
    }, [soldUsedLicensesProps])

    useEffect(() => {
        const formattedData = sul && sul.length > 0 ? sul?.map((row, i) => [
          // Include necessary fields in the exported CSV
          (i+1),
          // row.date, // If you want to include the date
          dateFormat(`0${row?.month}-${row?.year}`),
          row.assigned_count,
          row.used_count
        ]) : [];
        setDataToDownload(formattedData);
      }, [sul]);

    const setDatas = (soldUsedLicensesProps) => {
        console.log("initial data", monthlyDataSold)
        let data = []
        data = Array.isArray(soldUsedLicensesProps) ? soldUsedLicensesProps : []
        data && data?.map(item => {
            const monthIndex = item.month - 1; // Adjust index to start from 0
            if(Number(item?.assigned_count) >= 1) {
                monthlyDataSold[monthIndex] = Number(item?.assigned_count)
                monthlyDataUsed[monthIndex] = Number(item?.used_count)
                setMonthlyDataUsed(monthlyDataUsed)
                setMonthlyDataSold(monthlyDataSold)
            }
        });

        const mData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Nov', 'Dec'],
            datasets: [
                {
                    label: 'Sold',
                    backgroundColor: ['#146B90', '#146B90', '#146B90', '#146B90', '#146B90', '#146B90'],
                    data: monthlyDataSold,
                    barThickness: 30,
                    categoryPercentage: 1.1, // Adjust this value for spacing
                    barPercentage: 1.1, // Adjust this value for spacing
                    // stack: 'stack1',
                },
                {
                    label: 'Used',
                    backgroundColor: ['#D9E8EE', '#D9E8EE', '#D9E8EE', '#D9E8EE', '#D9E8EE', '#D9E8EE'],
                    data: monthlyDataUsed,
                    barThickness: 30,
                    categoryPercentage: 1.1, // Adjust this value for spacing
                    barPercentage: 1.1, // Adjust this value for spacing
                    // stack: 'stack1',
                },
            ],
        };
        setData(mData)
        setMonthlyDataUsed(Array(12).fill(0))
        setMonthlyDataSold(Array(12).fill(0))
    }

    const soldUsedLicenseF = (course_id, orgId, start, end) => {
        let formattedStartDate = start ? moment(start).format('YYYY-MM-DD') : undefined;
        let formattedEndDate = end ? moment(end).format('YYYY-MM-DD') : undefined;
        const org_id = orgInfo || selectedOrgId
        const token = apiService.getAuthToken()
        apiService.getanalytics(`sold-used-licenses?org_id=${org_id || ''}&startDate=${formattedStartDate || ''}&endDate=${formattedEndDate || ''}&courseId=${course_id || ''}`, token).then((response) => {
            console.log("response", response)
            if (response?.data) {
                if(response?.data && Array.isArray(response?.data)) {
                    setSUL(response?.data)
                } else {
                    setSUL([])
                }
                setDatas(response?.data)
            }
        })
    }

    useEffect(() => {
       fetchCourseData()
    }, [])

    const handleCourseSelect = (selectedOptions) => {
        setSelectedCourses(selectedOptions);
        if (startDate && endDate) {
            soldUsedLicenseF(selectedOptions.value, null, startDate, endDate)
        } else {
            soldUsedLicenseF(selectedOptions.value)
        }
    };

    const fetchCourseData  = (orgData) => {
        const token = apiService.getAuthToken()
        const org_id = orgInfo || selectedOrgId
        if(org_id) {
        apiService.get(`get_org_course_list/${org_id|| ''}`, token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                setCourseOptions(response?.data)
            }
        }).catch((error)=> {
            CustomToast.info(error)
        })
      } else {
        apiService.getCms(`dropdownCourseList`, token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                setCourseOptions(response?.data)
            }
        }).catch((error)=> {
            CustomToast.info(error)
        })
      }
    }

    // useEffect(() => {
    //     if (courseOptions.length > 0) {
    //         // Set the initial value to the first course in the list
    //         setSelectedCourses({
    //             value: courseOptions[0].course_id,
    //             label: courseOptions[0].course_title
    //         });
    //     }
    // }, [courseOptions]);

    
    const options = {
        indexAxis: 'y',
        categoryPercentage: 1.1, // Adjust this value for spacing
        barPercentage: 1.1, // Adjust this value for spacing
        responsive: true,
        hover: {
          animationDuration: 1
        },
        animation:{
          duration: 0,
          onComplete: function () {
            let myChart = this;
            let chartArea = myChart.chartArea;
            let { left, top } = chartArea;
            const datasets = myChart.data.datasets;
            for (let datasetIndex = 0; datasetIndex < datasets.length; datasetIndex++) {
              const meta = myChart.getDatasetMeta(datasetIndex);
              const data = datasets[datasetIndex].data;
      
              for (let index = 0; index < data.length; index++) {
                let bar = meta.data[index];
                let value = data[index];
                if (value != 0) {
                  let xPos = bar.x - barWidth;
                  let yPos = bar.y; // Adjust yPos for horizontal bar chart
                  
                  if (xPos < left) {
                    xPos = left + 2;
                  }
    
                  var ctx = myChart.ctx;
                  ctx.save();
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'middle';
                  ctx.fillStyle = 'black';
                  ctx.font = 'bold 12px Arial';
                  ctx.fillText(value, xPos, yPos);
                  ctx.restore();
                } 
              }
            }
          }
        },
        plugins: {
          tooltip: {
            enabled: true,
            position: 'nearest',
            // external: customTooltipRenderer,
          },
          legend: {
            display: false,
            position: 'bottom',
            borderRadius: 5
          }
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: true,
            },
          },
          y: {
            display: true,
            grid: {
              display: false,
            },
          }
        },
      };
    

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            soldUsedLicenseF(null, selectedOrgId, start, end)
        }
    };

    const clearDateRange = () => {
        setStartDate(null);
        setEndDate(null);
        soldUsedLicenseF(selectedCourses?.value, orgInfo, null, null)
      };

      const dateFormat = (dateString) => {
        return moment(dateString, 'MM-YYYY').format('Do MMM YYYY')
    }

    return (
        <div className="page__main"><div className="dashboard">
            <div className='statistics'>
                {/* {console.log(monthlyDataUsed)} */}
                <h3 className='fw-bold font-20 mb-4'>
                    {/* <a href='/license_sold_used' className='text-decoration-none text-black'>License Sold v/s Used</a> */}
                    <Link
                        to="/license_sold_used"
                        state={{ showDetails: false, soldUsedLicensesProps}}
                        className='text-decoration-none text-black'>
                            License Sold v/s Used
                    </Link>
                </h3>
                {showDetails && (
                    <div className='d-flex justify-content-between'>
                        <div className='mb-4 w-75'>
                            <div className='d-flex'>
                                <Select
                                   options={courseOptions?.map((ele) => ({
                                    value: ele.course_id,
                                    label: ele.course_title || ele.name
                                    }))}
                                    value={selectedCourses}
                                    onChange={handleCourseSelect}
                                    className='w-25 me-4'
                                    placeholder="Select Course"
                                />
                            </div>
                        </div>
                        <div className='d-flex justify-content-center align-items-start'>
                        <DatePicker
                            selectsRange
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDateChange}
                            maxDate={new Date()}
                            className='p-2 mb-4'
                            placeholderText="Select date range"
                        />
                        <button onClick={clearDateRange} className="btn-clear btn btn-md btn-secondary font-16 p-2">Clear</button>
                        </div>
                    </div>
                )}
                <div className='statistics sold-used bg-white p-4'>
                    {console.log("sold vs used", data)}
                   {data ?  <Bar type="bar" data={data} options={options} width={1000} height={900} /> : null }
                </div>

                {showDetails && (
                    <>

                        <div className="text-end my-4">
                            <CSVLink
                                data={[columns, ...dataToDownload]}
                                filename={`data.csv`}
                            >
                                <a className="btn btn-link export">CSV Export</a>
                            </CSVLink>
                        </div>

                        <table className="table table-striped table-responsive pt-5 mt-5">
                            <thead className="thead-dark">
                                <tr>
                                    <th className="text-center w-10">S. No.</th>
                                    <th>Month</th>
                                    <th>Sold</th>
                                    <th>Used</th>
                                </tr>
                            </thead>
                            <tbody>   
                           {sul&&sul?.map((item,i)=>{
                                 return<tr> <td align="center">{i+1}</td>
                                 <td>{dateFormat(`0${item?.month}-${item?.year}`)}</td>
                                 <td>{item?.assigned_count}</td>
                                 <td>{item?.used_count}</td>
                                 </tr>}
                            ) }
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </div>
        </div>
    );
};

export default LicenseSoldUsed;