import React, { useState, useEffect } from 'react';
import BarChart from './barChart';
import apiService from '../../../services/apiService';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import CustomToast from '../../Toast';
import ExtendsDateModal from '../modal/extendsDateModal';

Modal.setAppElement('#root');


const AssignedCourses = ({ orgData }) => {
    const [userDetail, setUserData] = useState()
    const [courseList, setCourseList] = useState([])
    const [selectedOption, setSelectedOption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [courseData, setCourseData] = useState([])
    const [toggleValue, setToggleValue] = useState(0)
    const [extendsDateModalIsOpen, setExtendsDateModalIsOpen] = useState(false);
    const [currentSelectedCourse, setCurrentSelectedCourse] = useState()

    const navigate = useNavigate()

    const openModal = (course_status_data) => {
        setCurrentSelectedCourse(course_status_data)
        setIsModalOpen(true);
    } 

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const openExtendsDateModal = () => {
        setExtendsDateModalIsOpen(true);
    };

    const closeExtendsDateModal = () => {
        setExtendsDateModalIsOpen(false);
    };

    const handleDropdownChange = (e) => {
      setSelectedOption(e.target.value);
      console.log('SelectedOption', selectedOption);
    }
    
    useEffect(() => { 
        let userDetail = sessionStorage.getItem("userDetail")
        userDetail = JSON.parse(userDetail)
        setUserData(userDetail)
        fetchData()
        //   fetchCourseList();
    }, []);

    const fetchData = async () => {
        try {
            const token = apiService.getAuthToken()
            const result = await apiService.getv1(`get-license-info?orgId=${orgData?.org_id}`, token);
            if (result && result.status === "success") {
                setCourseData(result.data);
            }
        } catch (error) {
            //   setError('Error fetching user data');
            console.error(error);
        }
    };

    const fetchCourseList = () => {
        const org_id = orgData?.org_id
        const token = apiService.getAuthToken()
        apiService.getv1(`get-org-details/${org_id}`, token).then(
            (response) => {
                setCourseList(response.data);
            },
            (error) => {
                console.error("Error fetching data", error);
            }
        );
    };


    const updateOrg = () => {
        const org_id = orgData?.org_id
        const token = apiService.getAuthToken()
        const formData = new FormData();
        if(toggleValue===0){
        formData.append("is_delete", 1)
        setToggleValue(1)
        }
    else{
        setToggleValue(0)
            formData.append("is_delete", false)
    }
        
      
        apiService.patchV1(`update-org/${org_id}`,formData, token).then(
            (response) => {
                setCourseList(response.data);
            },
            (error) => {
                console.error("Error fetching data", error);
            }
        );
    };
    

    const inviteUser = (data, courseData) => {
        localStorage.setItem("selectedCourse", JSON.stringify([courseData]))
        localStorage.setItem('selectedCourseObj', JSON.stringify(courseData));
        const filterCourse = data?.course_status?.map((ele => {
            const obj = {
                org_course_id: ele.org_course_id,
                course_id: ele.course_id,
                course_title: ele.name || ele.course_title
            }
            return obj
        })).filter((obj=> obj != undefined && obj != null))
        localStorage.setItem('inviteCourseList', JSON.stringify(filterCourse));
        localStorage.setItem("ORGID", data.org_id)
        const orgData = {
            org_id: data.org_id,
            org_name: data.org_name
        }
        localStorage.setItem("orgData", JSON.stringify(orgData))
        navigate("/course_invite")
    }

    const sendInvite = async(org_id, org_course_id) => {
        try {
            const updateForm = {
                org_id,
                org_course_id
            }
            const token = apiService.getAuthToken();
            const response = await apiService.post('course-reinvite', updateForm, token);
            if (response && response.status === 'success') {
                CustomToast.success('Reminder sent successfully')
                closeModal()
            //   navigate("/manage_user")
            } else {  
              closeModal()
            //   setError('Failed to update profile');
            //   CustomToast.error('Error while sending invitaion')
            }
            // setLoader(false);
          } catch (error) {
            closeModal()
            // setError('An error occurred while updating profile');
            console.error(error);
            // setLoader(false);
            // CustomToast.error('Error while sending invitaion')
          }
    }

    const sendNotify = async(org_id, course_id) => {
        const updateForm = {
            org_id,
            course_id,
            action: "notify" //push
        }

        try {
            const token = apiService.getAuthToken();
            const response = await apiService.postV1('notify-user-course', updateForm, token);
            if (response && response.status === 'success') {
                CustomToast.success('Notify to start course.')
                closeModal()
                // navigate("/manage_user")
            } else {
              closeModal()  
            //   setError('Failed to update profile');
            }
            // setLoader(false);
          } catch (error) {
            closeModal()
            // setError('An error occurred while updating profile');
            // setLoader(false);
            console.error(error);
            // CustomToast.error('Error while notify user')
          }
    }

    const sendPush = async(org_id, course_id) => {
        const  updateForm = {
            org_id,
            course_id,
            action:"push"
        }
        try {
            const token = apiService.getAuthToken();
            const response = await apiService.postV1('notify-user-course', updateForm, token);
            if (response && response.status === 'success') {
                CustomToast.success('Push to complete course.')
                closeModal()
                // navigate("/manage_user")
            } else {
              closeModal()  
            //   setError('Failed to update profile');
            }
            // setLoader(false);
          } catch (error) {
            closeModal()
            // setError('An error occurred while updating profile');
            console.error(error);
            // CustomToast.error('Error while notify user')
          }
    }

    return (
        <>
            <div className='course'>
                {orgData ? 
                <div className='d-flex justify-content-between align-items-center pt-4 my-4'>
                    <h3 className='fw-bold font-26'>Assigned Courses</h3>
                    <Link
                        // to={{
                        //     pathname: '/assign_new_course',
                        //     state: "courseData",
                        // }}
                        to="/assign_new_course"
                        state={{orgData}}
                        className="primary_color text-decoration-none fw-bold font-20"
                    >
                        Assign New Course
                    </Link>
                </div>
                : null }

                {courseData && courseData.map((data, index) => {
                    return <div key={1 + index}className="card mb-5">
                        {data && data?.course_status?.map((course_status_data, index) => {
                            return <div className="card">

                                <div className="card-body">
                                   {/* <input type="checkbox" data-toggle="toggle" data-size="xs" onChange={updateOrg} /> */} 
                                    <h3 className='primary_color text-capitalize font-26 fw-bold mb-3'>{course_status_data?.course_title}</h3>
                                    <p className='mb-4'>
                                        <span className="text-dark-gray text-capitalize me-4"><img src="/images/time.svg" className='me-2' />{course_status_data?.duration}</span>
                                        <span className="text-dark-gray text-capitalize"><img src="/images/modules.png" className='me-2' />{course_status_data?.total_modules} Modules</span>
                                    </p>
                                    <div className='row'>
                                       <div className='col-lg-3 col-6'>
                                           <a href={`/stats/${course_status_data?.course_id}/${course_status_data?.course_title}/${course_status_data?.org_course_id}`} className='btn btn-md btn-secondary p-2 text-decoration-none w-100'>View Stats</a>
                                        </div>
                                        <div className='col-lg-3 col-6'>
                                            <button className='btn btn-md btn-primary p-2 w-100' onClick={() => inviteUser(data, course_status_data)}>Invite Users</button>

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <BarChart course_status={course_status_data} />
                                        <div className='card-footer custom-bg p-1 text-center'>
                                            <button className='btn btn-link text-white text-decoration-none fw-bold' onClick={()=> openModal(course_status_data)}>Send Reminders</button>
                                        </div>
                                    </div>

                                </div>
                               {/* <div className='card-footer custom-bg p-2 text-center'>
                                    <p className='mb-0 text-white ss'>Course Expires on 30 Oct’2023</p>
                                </div> */} 
                            </div>
                        })}
                        {/*                 
                    <div className="card-body">
                        <h3 className='primary_color font-26 fw-bold mb-3'>Introduction to Diversity, Inclusion & Belonging (DIB)</h3>
                        <p className='mb-4'>
                            <span className="text-dark-gray me-4"><img src="/images/time.svg" className='me-2' />60 minutes</span>
                            <span className="text-dark-gray"><img src="/images/modules.png" className='me-2' />10 modules</span>
                        </p>

                        <div className='row'>
                            <div className='col-lg-3 col-6'>
                                <button className='btn btn-md btn-secondary w-100'>View Stats</button>

                            </div>
                            <div className='col-lg-3 col-6'>
                                <button className='btn btn-md btn-primary w-100'>Invite Users</button>
                            </div>
                        </div>

                        <div className='row'>
                            <BarChart />
                        </div>

                    </div> */}
                        {/* <div className='card-footer custom-bg p-2 text-center'>
                            <p className='mb-0 text-white'>Add Licences</p>
                        </div> */}
                       
                     {/* <Link
                        // to={{
                        //     pathname: '/assign_new_course',
                        //     state: "courseData",
                        // }}
                        to="/assign_new_course"
                        state={{orgData}}
                        className="text-decoration-none text-white font-18"
                    >
                        Add Licences
                    </Link> */}

                    </div>
                })}
                 <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} className="bg-white" >

                    <button type="button" className="close text-white" onClick={closeModal}> <svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                        <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
                    </svg></button>

                    <div className='p-3 border-0 mt-4 text-center'>

                        <h2 className='font-20 mb-5'>Send Reminder Notifications for Course: <span className='text-capitalize fw-bold'>{currentSelectedCourse?.course_title}</span></h2>

                        <div className='w-75 mb-3 m-auto'> <select className='form-select position-relative w-100 h-40 fw-bold' value={selectedOption} onChange={handleDropdownChange}>
                                <option value="select">Select</option>
                                <option value="invited">Remind to Accept Invitation </option>
                                <option value="notstarted">Remind to Start  Course</option>
                                <option value="inprogress">Remind to Complete The Course</option>
                            </select>
                            </div>

                        {selectedOption === 'invited' && (
                            <>
                                <h3 className='font-18 my-4'><span className='fw-bold'>{Number(currentSelectedCourse?.total_used) - Number(currentSelectedCourse?.accepted)}</span> users will be notified.</h3>
                                <button className='btn btn-md btn-primary'onClick={() => sendInvite(localStorage.getItem('ORGID'), currentSelectedCourse?.org_course_id)}>Notify</button>
                            </>
                        )}

                        {selectedOption === 'notstarted' && (
                            <>
                                <h3 className='font-18 my-4'><span className='fw-bold'>{currentSelectedCourse?.notstarted}</span> users will be notified.</h3>
                                <button className='btn btn-md btn-primary' onClick={() => sendNotify(localStorage.getItem('ORGID'), currentSelectedCourse?.course_id)}>Notify</button>
                            </>
                        )}

                        {selectedOption === 'inprogress' && (
                            <>
                                <h3 className='font-18 my-4'><span className='fw-bold'>{currentSelectedCourse?.inprogress}</span> users will be notified.</h3>
                                <button className='btn btn-md btn-primary' onClick={() => sendPush(localStorage.getItem('ORGID'), currentSelectedCourse?.course_id)}>Notify</button>
                            </>
                        )}                                       
                    </div>
                </Modal>
                {/* <div className="card">
                    <div className="card-body">
                        <h3 className='font-26 fw-bold mb-3'>Introduction to Diversity, Inclusion & Belonging (DIB)</h3>
                        <p className='mb-4'>
                            <span className="text-dark-gray me-4"><img src="/images/time.svg" className='me-2' />60 minutes</span>
                            <span className="text-dark-gray"><img src="/images/modules.png" className='me-2' />10 modules</span>
                        </p>

                        <div className='row'>
                            <div className='col-lg-3 col-6'>
                                <button className='btn btn-md btn-secondary w-100'>View Stats</button>

                            </div>
                            <div className='col-lg-3 col-6'>
                                <button className='btn btn-md btn-primary w-100'>Invite Users</button>

                            </div>

                        </div>

                        <div className='row'>
                            <BarChart />
                        </div>

                    </div>
                    <div className='card-footer custom-bg p-2 text-center'>
                        <p className='mb-0 text-white'>Add Licences</p>
                    </div>
                </div> */}

             <div className='d-flex justify-content-end align-items-center'>
                {/* <h3 className='fw-bold font-26 mb-4'>Licences History</h3> */}
               <button type='button' onClick={openExtendsDateModal} className='btn btn-md btn-secondary px-4 py-2'>Extend Course Date</button>
            </div>

              <Modal
                isOpen={extendsDateModalIsOpen}
                contentLabel="Active Modal"
            >
                <ExtendsDateModal
                    onRequestClose={closeExtendsDateModal}
                    orgInfo={orgData}
                    courseArr={courseData}
                />
            </Modal>

            </div>
        </>
    )

}

export default AssignedCourses;