import React, { useState, useEffect, useRef} from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import apiService from '../../../services/apiService'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Select from 'react-select';
import CustomToast from '../../Toast';
import Loader from '../../layouts/loader';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import './inviteToCourse/inviteToCourse.css'
const ManageUser = () => {
    const [courseLicenseList, setCourseLicenseList] = useState([]);
    const [selectedCourse, setSelectedCourses] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userData, setUserData] = useState()
    const [licenseInfo, setLicenseInfo] = useState(null)
    const [courseList, setCourseList] = useState([])
    const [orgDataInfo, setOrgData] = useState({})
    const [courseData, setCourseData] = useState([])
    const [currentOrgId, setCurrentOrgId] = useState('')
    const [totalCount, setTotalCount] = useState(1)
    const [sortOrder, setSortOrder] = useState('asc');
    const percentage = 0;
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [count, setCount] = useState('')
    const [isCsvModalOpen, setIsCsvModalOpen] = useState(false);
    const [csvFile, setCsvFile] = useState()

    const openModal = () => {
        setIsModalOpen(true);
    } 

    const closeModal = () => {
        setIsModalOpen(false);
        clearTheForm();
    }

    const handleDropdownChange = (e) => {
      setSelectedOption(e.target.value);
     // openBulkDeactivateModal();
      console.log('SelectedOption', selectedOption);
    }
    
    const onDrop = (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(file);
      setSelectedFileName(file.name);
    };
    
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: ['.csv'],
    });
    
    const handleSubmit = async(e) => {
      e.preventDefault();
      try {
            if (!file) {
            CustomToast.info('Please upload a file.');
            return;
            }
            const fileType = file.type;
            if (fileType !== 'text/csv') {
            CustomToast.info('Please upload a CSV file.');
            return;
            }
            setLoader(true)
            const formData = new FormData();
            formData.append('image', file);
            formData.append('org_id', localStorage.getItem('ORGID'));
            formData.append('org_course_id', selectedCourse?.value);
            formData.append('type', selectedOption);
            const token = apiService.getAuthToken()
            const result = await apiService.put('bulk_revoke_and_activate', formData, token);
            if (result && result.status === "success") {
                if(result?.data?.file) {
                    setSelectedValue(null);
                    setLoader(false)
                    closeModal()
                    setCount(result?.data?.count || 0)
                    setCsvFile(result?.data?.file)
                    openCsvModal();
                } else {
                    CustomToast.success(`Bulk ${selectedOption == "revoke" ? 'Revoke' : 'Deactivate'} completed successfully`)
                    setSelectedValue(null);
                    setLoader(false)
                    closeModal()
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 1000);
                }
            } else {
                setLoader(false)
                closeModal()
            }
        } catch (error) {
            setLoader(false)
            closeModal()
            if(error && error.message && error.message.includes("Couldn't find the license might be license date was expired")) {
                CustomToast.info('Course invite already sent.')
              } else if(error && error.message && error.message.includes('Not enough licenses available')) {
                CustomToast.info('Insufficient licenses')
              } else if(error && error.message && error.message.includes("Licenses are not enough to accommodate the requested file")) {
                CustomToast.info('Insufficient licenses')
              } else if(error && error.message && error.message.includes("Licenses are not available for provided course")) {
                CustomToast.info('Licenses are not available for provided course')
              } else if(error && error.message && error.message.includes("Please provide the proper email address")) {
                CustomToast.info('Please provide the proper email address')
              } else if(error && error.message && error.message.includes("CSV file is empty or invalid")) {
                CustomToast.info('No data found in CSV')
              }
          }
    };
    
    const clearTheForm = () => {
      setFile(null);
      setSelectedValue(null);
      setSelectedFileName(null)
    };

    const openCsvModal = () => {
        setIsCsvModalOpen(true);
      };
    
      const closeCsvModal = () => {
        setIsCsvModalOpen(false);
        window.location.reload(true);
      };

    useEffect(() => {
        setSelectedOption('');
        window.scrollTo(0, 0);
        let userDetail = sessionStorage.getItem("userDetail")
        setCurrentOrgId(localStorage.getItem('ORGID'))
        if (userDetail) {
            userDetail = JSON.parse(userDetail)
            setUserData(userDetail)
        }
        let orgData = localStorage.getItem("orgData")
        if(orgData) {
            orgData = JSON.parse(orgData)
            setOrgData(orgData)
        }
        getOrgCourseList(orgData)
        // fetchLicenseByOrgId(orgData)
        //fetchCourseLicenses(orgData);
        fetchData(orgData)
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentPage]);
   // select default value
    useEffect(() => {
        setSelectedOption('');
        if (courseList.length > 0) {
            // Set the initial value to the first course in the list
            setSelectedCourses({
                value: courseList[0].org_course_id,
                label: courseList[0].name || courseList[0].course_title,
                course_id: courseList[0].course_id
            });
            const create_obj = {
                org_course_id: courseList[0].org_course_id,
                course_id: courseList[0].course_id,
                course_title: courseList[0].name || courseList[0].course_title
            }
            localStorage.setItem("selectedCourse", JSON.stringify([create_obj]))
            localStorage.setItem('selectedCourseObj', JSON.stringify(create_obj))
            const filterCourse = courseList.map((ele => {
                const obj = {
                    org_course_id: ele.org_course_id,
                    course_id: ele.course_id,
                    course_title: ele.name || ele.course_title
                }
                return obj
            })).filter((obj=> obj != undefined && obj != null))
            localStorage.setItem('inviteCourseList', JSON.stringify(filterCourse));
            fetchCourseLicenses(null, courseList[0].org_course_id);
        }
    }, [courseList]);

    // get coursedetails org_id
    // get license info against org_id

    const handleCourseSelect = (selectedOptions) => {
        setSelectedOption('');
        const create_obj = {
            org_course_id: selectedOptions.value,
            course_id: selectedOptions.course_id,
            course_title: selectedOptions.label
        }
        localStorage.setItem("selectedCourse", JSON.stringify([create_obj]))
        localStorage.setItem('selectedCourseObj', JSON.stringify(create_obj))
        fetchLicenseByOrgId(orgDataInfo, selectedOptions.value, selectedOption)
        setCourseLicenseList([])
        fetchCourseLicenses(orgDataInfo, selectedOptions.value, '')
        setSelectedCourses(selectedOptions);
    };

    const handleSelectChange = (event) => {
        console.log(event.target.value);
        setSelectedOption(event.target.value);
        fetchCourseLicenses(orgDataInfo, selectedCourse.value, event.target.value)
    };

    const fetchLicenseByOrgId = (orgData, org_course_id) => {
        // setLoading(true);
        setLoader(true);
        const token = apiService.getAuthToken()
        const org_id = localStorage.getItem('ORGID') //orgData?.org_id
        org_course_id = org_course_id
        apiService.get(`get_license_status/${org_id}?org_course_id=${org_course_id}`, token).then(
            (response) => {
                setLicenseInfo(response.data);
                setLoader(false);
            },
            (error) => {
                console.error("Error fetching data", error);
                setLoader(false);
            }
        );
    };

    const fetchCourseLicenses = (orgData, org_course_id, status) => {
        setLoading(true);
        setLoader(true);
        const token = apiService.getAuthToken()
        const org_id = localStorage.getItem('ORGID') //orgData?.org_id 
        apiService.get(`get_user_course_list_by_org_id/${org_id}?pageSize=10&page=${currentPage}&org_course_id=${org_course_id}&status=${status || ''}`, token).then(
            // (response) => {
            //     setCourseLicenseList((prevData) => {
            //         const uniqueOrgs = response?.data.filter(
            //           (org) => !prevData.some((prevOrg) => prevOrg.name === org.name)
            //         ) || [];
            //         return [...prevData, ...uniqueOrgs];
            //       });
            //     setLoading(false);
            //     setLoader(false);
            // },
            // (error) => {
            //     setLoading(false);
            //     setLoader(false);
            //     console.error("Error fetching data", error);
            // }

            (response) => {
                if (status !== selectedOption) {
                    setCourseLicenseList(response.data);
                } else {
                    setCourseLicenseList((prevData) => {
                        const uniqueOrgs = response?.data.filter(
                            (org) => !prevData.some((prevOrg) => prevOrg.name === org.name)
                        ) || [];
                        return [...prevData, ...uniqueOrgs];
                    });
                }
                setLoading(false);
                setLoader(false);
            },
            (error) => {
                setLoading(false);
                setLoader(false);
                console.error("Error fetching data", error);
            }
        );
    };

    function handleEditProfileClick(element) {
        // console.log(element)
        navigate('/manage_user/edit_profile', { state: { userData: element } });
    }

    const handleScroll = () => {
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrollTrigger = 0.9;

        if (scrollTop + clientHeight >= scrollHeight * scrollTrigger && !loading && totalCount > currentPage) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    function onSearch(e) {
        const value = e.target.value
        if (value) {
            const filteredOptions = courseLicenseList.filter(option =>
                option.name.toLowerCase().includes(value.toLowerCase())
            );
            setCourseLicenseList(filteredOptions)
        } else {
            setCourseLicenseList([])
            fetchCourseLicenses(orgDataInfo, selectedCourse?.value, '')
        }
    }

    const handleSort = () => {
        // Toggle the sorting order between 'asc' and 'desc'
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
      
        // Sort the courseLicenseList based on the name and sorting order
        setCourseLicenseList((prevData) => {
          return [...prevData].sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
      
            if (newSortOrder === 'asc') {
              return nameA.localeCompare(nameB);
            } else {
              return nameB.localeCompare(nameA);
            }
          });
        });
      };

    const getOrgCourseList = (orgData) => {
        const token = apiService.getAuthToken()
        const orgid = localStorage.getItem('ORGID') // orgData?.org_id
        apiService.get(`get_org_course_list/${orgid}`, token).then(
            (response) => {
                if (response.status == 'success') {
                    const create_obj = {
                        org_course_id: response?.data[0].org_course_id,
                        course_id: response?.data[0].course_id,
                        course_title: response?.data[0].name || response?.data[0].course_title
                    }
                    localStorage.setItem("selectedCourse", JSON.stringify([create_obj]))
                    localStorage.setItem('selectedCourseObj', JSON.stringify(create_obj))
                    fetchLicenseByOrgId({org_id: orgid}, response.data[0].org_course_id, selectedOption)
                    fetchCourseLicenses(orgData, response.data[0].org_course_id,selectedOption);
                    setCourseList(response.data)
                }
                setLoader(false);
            },
            (error) => {
                setLoader(false);
                console.error("Error fetching data", error);
            }
        );
    }

    const assignedLicenseReq = () => {
        const bodyData = {
            org_course_id: selectedCourse?.value,
            user_id: userData?.uid,
            org_id: orgDataInfo?.org_id || localStorage.getItem('ORGID'),
            no_license:"100"
        }

        const token = apiService.getAuthToken()
        apiService.postV1(`license-request`, bodyData, token).then(
            (response) => {
                if(response.status == 'success') {
                    CustomToast.success("Sent main add license request")
                    navigate('/manage_user')
                }
                setLoader(false);
            },   
            (error) => {
            console.error("Error fetching data", error);
            CustomToast.error(error)
            setLoader(false)
            }
        );
    }

    const fetchData = async (orgData) => {
        try {
            const token = apiService.getAuthToken()
            const result = await apiService.getv1(`get-license-info?orgId=${localStorage.getItem('ORGID')}`, token);
            if (result && result.status === "success") {
                setCourseData(result.data);
            }
        } catch (error) {
            //   setError('Error fetching user data');
            console.error(error);
        }
    };


    const inviteUser = () => { 
        if(courseData && courseData.length >  0 ) {
            // localStorage.setItem("selectedCourse", JSON.stringify(courseData[0].course_status))
            // localStorage.setItem('selectedCourseObj', JSON.stringify(courseData[0].course_status[0]));
            localStorage.setItem("ORGID", courseData[0].org_id)
            const orgData = {
                org_id: courseData[0].org_id,
                org_name: courseData[0].org_name
            }
            localStorage.setItem("orgData", JSON.stringify(orgData))
            navigate("/course_invite")
        } else {
            navigate("/course_invite")
        }
    }

    function handleEditProfileClick(element) {
        // console.log(element)
        navigate('/manage_user/edit_profile', { state: { userData: element } });
    }

    return (
        <>
          <div className='page__main'>
          <div className="dashboard" style={{minHeight: '100vh'}}>
              <div className="d-flex align-items-center justify-content-between manage-user fw-bold mb-4">
                  <div className="dropdown safe-workspace w-25">
                      <Select
                          id="orgSizeSelect"
                          name="selectedOrgSize"
                          placeholder="Select course"
                          value={selectedCourse || courseList[0]}
                          options={courseList.map((option) => ({
                              value: option.org_course_id,
                              label: option.name || option.course_title,
                              course_id: option.course_id
                          }))}
                          onChange={handleCourseSelect}>   
                      </Select>
                  </div>
                    <div>
                    <Link
                        to="/assign_new_course"
                        state={{orgDataInfo}}
                        className="text-decoration-none d-flex align-items-center font-20 justify-content-between"
                       > 
                        <span className='me-1'><svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0842 8.73415C18.1691 4.64923 24.8301 4.64923 28.9335 8.75255C33.0184 12.8375 33.0184 19.4985 28.9335 23.5834C24.8301 27.6867 18.1691 27.6867 14.0842 23.6018C9.98089 19.4985 9.98089 12.8375 14.0842 8.73415ZM20.4324 10.3718L20.4508 15.1007L15.7035 15.1007V17.2352L20.4508 17.2352L20.4324 21.9641H22.5669L22.5853 17.2352H27.2958V15.1007H22.5853L22.5669 10.3718H20.4324Z" fill="#146B90"></path></svg> </span>
                         Add More Licences
                    </Link>
                    </div>
              </div>

              <div className="row m-0 licence_box">
                  <div className='col-md-7 d-flex justify-content-between align-items-center'>
                      <div><h2 className='text-white fw-bold fs-1'>{licenseInfo?.used}</h2>
                          <p className='font-20 text-white mb-0'>
                              <span className='me-2'><img src="/images/licences.svg" width={24} /></span>
                              Licences Used
                          </p></div>
                      <div>
                          <h2 className='text-white fw-bold fs-1'>{isNaN(Number(licenseInfo?.assigned) - Number(licenseInfo?.used)) ? 0 : Number(licenseInfo?.assigned) - Number(licenseInfo?.used)}</h2>
                          <p className='font-20 text-white mb-0'>
                              <span className='me-2'><img src="/images/remaining.svg" width={24} /></span>
                              Licences Remaining
                          </p>
                      </div>
                  </div>

                  <div className='col-md-5'>
                      <div className='licences_btn'>
                      <a href="javascript:void(0)" onClick={() => inviteUser()} className="btn btn-md d-flex justify-content-center align-items-center btn-secondary-color licences_btn px-3">
                            <img src="/images/account_plus.svg" className="add_btn" />
                            <span className="button-default text-black">Invite New User</span>
                        </a>
                      </div>
                  </div>

              </div>
              <div className='hori_line'></div>

              <div className='d-flex justify-content-between align-items-center w-100'>
                    <div className="form-group has-search">
                        <span className="form-control-feedback"><img src="/images/search.svg" alt='search' width={35} /></span>
                        <input type="text" className="form-control w-100" placeholder="Search" onChange={onSearch} />
                    </div>
                    <div><button className='btn btn-md btn-secondary py-2' onClick={openModal}>Bulk Action</button></div> 
                </div>

              {/* <div className="form-group has-search w-25">
                  <span className="form-control-feedback"><img src="/images/search.svg" /></span>
                  <input type="text" className="form-control" placeholder="Search" onChange={onSearch} />
                  <div><button className='btn btn-md btn-secondary py-2' onClick={openModal}>Bulk Action</button></div>
              </div> */}
              <div>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} className="bg-white max-width-600 p-4">
                <button type="button" className="close text-white" onClick={closeModal}> <svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
                </svg></button>

                <div className='border-0 w-75 my-4 mx-auto text-center'> 

                <h2 className='font-28 fw-bold mb-3'>Bulk Action</h2>
                <p className='mb-3 fw-medium font-16'>Please select an action from menu below</p>

                        <div className='mx-auto w-100 position-relative'> <select className='form-select position-relative w-100 h-40 fw-bold' value={selectedOption} onChange={handleDropdownChange}>
                            <option value="select">Select Action</option>
                            {/* <option value="deactivate">Remind to Accept Invitation </option>
                            <option value="revoked">Remind to Start Course</option>
                            <option value="revoked">Remind to complete Course</option>*/}
                            <option value="revoke">Bulk revoke licences</option>
                            <option value="deactivated">Bulk deactivate users</option>
                            </select>
                        </div>

                    {selectedOption && (
                        <>
                        
                        <div className='bulk-deactivate mt-3 mx-auto w-100 position-relative'>
                            {loader ? <Loader /> :
                                <form className='bulk-upload' onSubmit={handleSubmit}>

                                    <div {...getRootProps()} className='dropzone'>
                                        <input {...getInputProps()} />
                                        <p className='font-18'><strong>Upload your file here</strong> </p>
                                        <button type="button" className='file-upload-btn'>
                                            Choose File
                                        </button>
                                        {selectedFileName && (
                                            <p className='selected-file-name font-14'>{selectedFileName}</p>
                                        )}
                                    </div>
                                    <button type="submit" className='btn btn-md btn-danger w-50 mt-4'>
                                        {selectedOption == 'revoke' ? 'Revoke' : 'Deactivate'}
                                    </button>
                                </form> }
                            {loader ? null : <p className='download-csv text-center mt-3 mb-0'>
                                <a href={process.env.PUBLIC_URL + '/templates/bulkrevokeanddeactivate.csv'} className='font-weight-600'>
                                Download CSV or Excel template here
                                </a>
                            </p> }
                        </div>

                        {/* 
                            <h3 className='font-18 my-4'><span className='fw-bold'>10</span> users will be notified.</h3>
                            <button className='btn btn-md btn-primary'>Notify</button> */}

                        </>
                    )}

                    </div>
                </Modal>
                <Modal
                    isOpen={isCsvModalOpen}
                    onRequestClose={closeCsvModal}
                    contentLabel="Modal"
                    className='Modal bg-white w-25 p-4'
                    >
                    <button type="button" className="close text-white" onClick={closeCsvModal}><svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                    <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
                    </svg></button>

                    <div className="py-4 px-4 w-100 text-center">
                    <p className='font-16 fw-bold mt-3'>Bulk action failed for {count} users. Please download the CSV file to view detailed errors and retry.</p>
                    <div className='text-center mt-4'>
                        <a href={csvFile} className='btn btn-primary py-2' download>
                        Download CSV
                        </a>
                    </div>
                    </div>
                </Modal>
              </div>
                    <div className="w-100" style={{overflowX:'auto'}}>
                        <table className="table table-striped table-responsive">
                            <thead className="thead-dark">
                                <tr>
                                    <th className='text-center'>S. No.</th>
                                    <th>Entity</th>
                                    <th>
                                        <span onClick={handleSort} className='d-flex justify-content-between align-items-center'>User Name <img src='/images/sort.svg' alt='sort' className='me-5' width={20} height={20} /></span>
                                    </th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Status
                                        <span className='ms-3 w-10'>
                                            <select id="filter-select" value={selectedOption} onChange={handleSelectChange}>
                                                <option value="">All</option>
                                                <option value="INVITED">Invited</option>
                                                <option value="notstarted">Not Started</option>
                                                <option value="inprogress">Inprogress</option>
                                                <option value="completed">Completed</option>
                                                <option value="REVOKED">Revoked</option>
                                            </select>
                                        </span>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {loader ? <Loader />
                                : <tbody>
                                    {courseLicenseList && courseLicenseList?.map((element, index) => {
                                        return <tr>
                                            <td align='center'>{index + 1}</td>
                                            <td>{element?.org_entity ? element?.org_entity : 'NA'}</td>
                                            <td>{element.name}</td>
                                            <td>{element.email}</td>
                                            <td>{element.mobile}</td>
                                            <td className="text-center">
                                                <div className='progressbar d-flex justify-content-end align-items-center'>
                                                    {element?.merged_status == 'notstarted' || element?.merged_status == 'completed' || element?.merged_status == 'inprogess' || element?.merged_status == 'inprogress' ?
                                                        <CircularProgressbar
                                                            value={element.completion_percent || 0}
                                                            text={`${element.completion_percent || 0}%`}
                                                            strokeWidth={10}
                                                        />
                                                        : null
                                                    }

                                                    {element?.merged_status == 'notstarted' &&
                                                        <span className="badge ms-2 rounded-pill notstarted-badge">Not Started</span>
                                                    }

                                                    {element?.merged_status == 'completed' &&
                                                        <span className="badge ms-2 rounded-pill completed-badge">Completed</span>
                                                    }


                                                    {element?.merged_status == 'inprogress' &&
                                                        <span className="badge ms-2 rounded-pill inprogress-badge">In Progress</span>
                                                    }

                                                    {element?.merged_status == 'notaccepted' &&
                                                        <span className="badge ms-2 rounded-pill notaccepted-badge">Not Accepted</span>
                                                    }

                                                    {element?.merged_status == 'INVITED' &&
                                                        <span className="badge ms-2 rounded-pill notaccepted-badge">Invited</span>
                                                    }

                                                    {(element?.merged_status == 'deactivate' || element?.merged_status == 'deactivated') &&
                                                        <span className="badge ms-2 rounded-pill notaccepted-badge">Deactivate</span>
                                                    }


                                                    {element?.merged_status == 'revoked' || element?.merged_status == 'REVOKED' &&
                                                        <span className="badge ms-2 rounded-pill revoked-badge">Revoked</span>
                                                    }
                                                </div>
                                            </td>
                                            <td><a href="javascript:void(0)" onClick={() => handleEditProfileClick(element)} ><img src="/images/pencil.svg" width={20} height={20} ></img></a></td>
                                        </tr>
                                    })}
                                </tbody>}
                        </table>
                    </div>

          </div>
        </div> 
        </>
    )

}

export default ManageUser;