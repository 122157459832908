import React, { useState, useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './dashboard.css';
import apiService from '../../../services/apiService';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import CustomToast from '../../Toast'

const CoursePerformance = ({ showDetails = true, coursePerformanceProps, selectedOrgId}) => {
    const [activeFilter, setActiveFilter] = useState('all');
    const [orgInfo, setOrgInfo] = useState()
    const [selectedOrg, setSelectedOrg] = useState()
    const [coursePerformance,setCoursePerformance]=useState([]);
    const barWidth = 30;

    // const mData = {
    //   labels: ['A'],
    //     datasets: [
    //       {
    //         label: 'All',
    //         data: [7.2],
    //         backgroundColor: '#D9E8EE',
    //         barThickness: 30,
    //       },
    //       {
    //         label: 'All',
    //         data: [9.6],
    //         backgroundColor: '#146B90',
    //         barThickness: 30,
    //       },
    //     ],
    // };

    const [data, setData] = useState()

    useEffect(() => {
      window.scrollTo(0, 0);
      
      if(coursePerformanceProps) {
        setDatas(coursePerformanceProps);
        setCoursePerformance(coursePerformanceProps)
        setOrgInfo(selectedOrgId)
      }
    }, [coursePerformanceProps, activeFilter])

    const setDatas = (coursePerformanceProps, filter) => {
      coursePerformanceProps = Array.isArray(coursePerformanceProps) ? coursePerformanceProps : typeof coursePerformanceProps == 'object' ? [coursePerformanceProps] : []
      if(coursePerformanceProps && coursePerformanceProps.length > 0) {
        if(filter == 'all') {
          const labels = coursePerformanceProps && coursePerformanceProps?.map(course => course?.course_name)
          const baselineData = coursePerformanceProps?.map(course => {
            if(course?.tests_result) {
              const data = course?.tests_result.map(ele => {
                if(ele.test_type == "base_line") {
                  return Math.round(ele?.average_marks);
                }
              }).filter(ele => ele !=null || ele !=undefined)
              return data[0]
            }
          }).filter(ele => ele !=null || ele !=undefined)
          const endLineData = coursePerformanceProps?.map(course => {
            if(course?.tests_result) {
              const data =  course?.tests_result.map(ele => {
                if(ele.test_type == "end_line") {
                  return Math.round(ele?.average_marks);
                }
              }).filter(ele => ele !=null || ele !=undefined)
              return data[0]
            }
          }).filter(ele => ele !=null || ele !=undefined)
          const basline = {
            label: 'BaseLine',
            data: baselineData,
            backgroundColor: ['#146B90', '#146B90', '#146B90', '#146B90', '#146B90', '#146B90'],
            barThickness: 30,
          }
          const endLine = {
            label: 'EndLine',
            data:  endLineData,
            backgroundColor: ['#D9E8EE', '#D9E8EE', '#D9E8EE', '#D9E8EE', '#D9E8EE', '#D9E8EE'],
            barThickness: 30,
          }
          const mData = {
              labels: labels,
              datasets: [basline, endLine]
          };
          setData(mData)
        } 
        else if(filter == 'department') {
          const departmentDatas = coursePerformanceProps[0];
          const labels = Object.keys(departmentDatas).filter(key => departmentDatas[key].length > 0)
          let endLineData = [];
          let baseLineData = [];
          for(let lebel in labels) {
            const departmentData = departmentDatas[labels[lebel]];
            for(let i  in departmentData) {
              if(departmentData[i].test_type === "end_line") {
                endLineData.push(Math.round(departmentData[i].average_marks))
              } 
              if(departmentData[i].test_type === "base_line") {
                baseLineData.push(Math.round(departmentData[i].average_marks))
              }
            }
          }
          
          const mData = {
              labels: labels,
              datasets: [
                {
                    label: 'baseline',
                    data: baseLineData,
                    backgroundColor: '#D9E8EE',
                    barThickness: 30,
                },
                {
                    label: 'endline',
                    data: endLineData,
                    backgroundColor: '#146B90',
                    barThickness: 30,
                },
            ]
          };
          setData(mData);
        } else if(filter == 'gender') {
          const genderDatas = coursePerformanceProps[0];
          const labels = Object.keys(genderDatas).filter(key => genderDatas[key].length > 0)
          let endLineData = [];
          let baseLineData = [];
          for(let lebel in labels) {
            const genderData = genderDatas[labels[lebel]];
            for(let i  in genderData) {
              if(genderData[i].test_type === "end_line") {
                endLineData.push(Math.round(genderData[i].average_marks))
              } 
              if(genderData[i].test_type === "base_line") {
                baseLineData.push(Math.round(genderData[i].average_marks))
              }
            }
          }
          const mData = {
              labels: labels,
              datasets: [
                {
                    label: 'baseline',
                    data: baseLineData,
                    backgroundColor: '#D9E8EE',
                    barThickness: 30,
                },
                {
                    label: 'endline',
                    data: endLineData,
                    backgroundColor: '#146B90',
                    barThickness: 30,
                },
            ]
          };
          setData(mData);
        } else if(filter == 'age_group') {
          const ageGroupDatas = coursePerformanceProps[0];
          const labels = Object.keys(ageGroupDatas).filter(key => ageGroupDatas[key].length > 0)
          let endLineData = [];
          let baseLineData = [];
          for(let lebel in labels) {
            const ageGroupData = ageGroupDatas[labels[lebel]];
            for(let i  in ageGroupData) {
              if(ageGroupData[i].test_type === "end_line") {
                endLineData.push(Math.round(ageGroupData[i].average_marks))
              } 
              if(ageGroupData[i].test_type === "base_line") {
                baseLineData.push(Math.round(ageGroupData[i].average_marks))
              }
            }
          }
          
          const mData = {
              labels: labels,
              datasets: [
                {
                    label: 'baseline',
                    data: baseLineData,
                    backgroundColor: '#D9E8EE',
                    barThickness: 30,
                },
                {
                    label: 'endline',
                    data: endLineData,
                    backgroundColor: '#146B90',
                    barThickness: 30,
                },
            ]
          };
          setData(mData);
        } else {
          const labels = coursePerformanceProps && coursePerformanceProps?.map(course => course?.course_name)
          const baselineData = coursePerformanceProps?.map(course => {
            if(course?.tests_result) {
              const data =  course?.tests_result.map(ele => {
                if(ele.test_type == "base_line") {
                  return Math.round(ele?.average_marks);
                }
              }).filter(ele => ele !=null || ele !=undefined)
              return data[0]
            }
          }).filter(ele => ele !=null || ele !=undefined)
          const endLineData = coursePerformanceProps?.map(course => {
            if(course?.tests_result) {
              const data = course?.tests_result.map(ele => {
                if(ele.test_type == "end_line") {
                  return Math.round(ele?.average_marks);
                }
              }).filter(ele => ele !=null || ele !=undefined)
              return data[0]
            }
          }).filter(ele => ele !=null || ele !=undefined)
          const basline = {
            label: 'BaseLine',
            data: baselineData,
            backgroundColor: ['#146B90', '#146B90', '#146B90', '#146B90', '#146B90', '#146B90'],
            barThickness: 30,
          }
          const endLine = {
            label: 'EndLine',
            data:  endLineData,
            backgroundColor: ['#D9E8EE', '#D9E8EE', '#D9E8EE', '#D9E8EE', '#D9E8EE', '#D9E8EE'],
            barThickness: 30,
          }
          const mData = {
              labels: labels,
              datasets: [basline, endLine]
          };
          setData(mData)
        }
      } else {
        setData([])
      }
    }

    const soldCoursePerformance=(orgId, start, end, filter)=>{
      let formattedStartDate = start ? moment(start).format('YYYY-MM-DD') : undefined;
      let formattedEndDate = end ? moment(end).format('YYYY-MM-DD') : undefined;
      const org_id = selectedOrgId
      const today = moment();
      const oneMonthAgo = moment(today).subtract(30, 'days');
      const defaultStartDate = moment(oneMonthAgo).format('YYYY-MM-DD');
      const defaultEndDate = moment(today).format('YYYY-MM-DD');
      const token = apiService.getAuthToken()
      apiService.getanalytics(`course-performance?org_id=${org_id||''}&startDate=${formattedStartDate || ''}&endDate=${formattedEndDate || ''}&analytics_category=course_performance&filter=${filter && filter == 'all' ? '': filter  || ''}`,token).then((response)=> {
          console.log("response", response)
          if(response?.data) {
            setDatas(response?.data, filter)
            setCoursePerformance(response?.data)
          }
      }).catch((error)=> {
        CustomToast.error('Something went wrong')
      })
    }

    useEffect(() => {
        if (!coursePerformanceProps) {
          soldCoursePerformance()
        }

    }, [])

    const handleFilterClick = (filter) => {
      setActiveFilter((prevFilter) => {
        if (prevFilter !== filter) {
          soldCoursePerformance(null, null, null, filter);
        }
        return filter;
      });
    };

    const options = {
      categoryPercentage: 2.1, // Adjust this value for spacing
      barPercentage: 2.1, // Adjust this value for spacing
      indexAxis: 'y',
      responsive: true,
      hover: {
        animationDuration: 1
      },
      animation:{
        duration: 0,
        onComplete: function () {
          let myChart = this;
          let chartArea = myChart.chartArea;
          let { left, top } = chartArea;
          const datasets = myChart.data.datasets;
          for (let datasetIndex = 0; datasetIndex < datasets.length; datasetIndex++) {
            const meta = myChart.getDatasetMeta(datasetIndex);
            const data = datasets[datasetIndex].data;
    
            for (let index = 0; index < data.length; index++) {
              let bar = meta.data[index];
              let value = data[index];
              if (value != 0) {
                let xPos = bar.x - barWidth;
                let yPos = bar.y; // Adjust yPos for horizontal bar chart
                
                if (xPos < left) {
                  xPos = left + 2;
                }
  
                var ctx = myChart.ctx;
                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillStyle = 'black';
                ctx.font = 'bold 12px Arial';
                ctx.fillText(value, xPos, yPos);
                ctx.restore();
              } 
            }
          }
        }
      },
      plugins: {
        tooltip: {
          enabled: true,
          position: 'nearest',
          // external: customTooltipRenderer,
        },
        legend: {
          display: false,
          position: 'bottom',
          borderRadius: 5
        }
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: true,
          },
        },
        y: {
          display: true,
          grid: {
            display: false,
          },
        }
      },
    };

  return (
    <div className="page__main">
    <div className="dashboard">
    <h3 className='fw-bold font-20 mb-4'><a href='/course_performance' className='text-decoration-none text-black'>Course Performance</a></h3>
        {showDetails && (
          <>
            <div className='filters mb-4'>
              <a href="javascript:void(0)" className='btn btn-link text-decoration-none me-2'
                onClick={() => handleFilterClick('all')}
                style={{ color: activeFilter === 'all' ? '#146B90' : '#000000' }}
              >
                All
              </a>
              <a href="javascript:void(0)" className='btn btn-link text-decoration-none me-2'
                onClick={() => handleFilterClick('department')}
                style={{ color: activeFilter === 'department' ? '#146B90' : '#000000' }}
              >
                Department
              </a>
              <a href="javascript:void(0)" className='btn btn-link text-decoration-none me-2'
                onClick={() => handleFilterClick('age_group')}
                style={{ color: activeFilter === 'age_group' ? '#146B90' : '#000000' }}
              >
                Age
              </a>
              <a href="javascript:void(0)" className='btn btn-link text-decoration-none me-2'
                onClick={() => handleFilterClick('gender')}
                style={{ color: activeFilter === 'gender' ? '#146B90' : '#000000' }}
              >
                Gender
              </a>
            </div>
            {data && Object.keys(data).length > 0 ? <div className='statistics bg-white p-3 mb-5'>  <Bar data={{
              labels: data?.labels,
              datasets: data?.datasets,
            }} options={options} /></div> : null}
          </>
        )}

    {coursePerformance && coursePerformance.length > 0 && activeFilter == 'all' && coursePerformance?.map((course, index) => (
      <div className='statistics bg-white p-3 mb-4'>
        <div key={index}>
          <h3 className='statistics text-capitalize p-3 fw-bold font-20 radius-0'>{course?.course_name}</h3>
          <div className='statistics bg-white p-3 mt-4 d-flex flex-wrap'>
            {course?.tests_result?.map((testResult, i) => (
              <div className="col-3 mb-2 text-center" key={i}>
                {testResult.test_type === 'base_line' ? (
                  <>
                    <h2 className="fw-bold font-26">{Math.round(testResult?.average_marks)}</h2>
                    <h3 className="fw-medium font-18 mt-2">Avg. Base Line</h3>
                  </>
                ) : testResult.test_type === 'end_line' ? (
                  <>
                    <h2 className="fw-bold font-26">{Math.round(testResult?.average_marks)}</h2>
                    <h3 className="fw-medium font-18 mt-2">Avg. End Line</h3>
                  </>
                ) : testResult.test_type === 'end_line' ? (
                  <>
                    <h2 className="fw-bold font-26">{Math.round(testResult?.average_attempts)}</h2>
                    <h3 className="fw-medium font-18 mt-2">Avg. Attempts</h3>
                  </>
                ) : testResult.test_type === 'end_line' ? (
                  <>
                    <h2 className="fw-bold font-26">{course?.change_percent ? Math.round(course?.change_percent) : 'N/A'}</h2>
                    <h3 className="fw-medium font-18 mt-2">Change</h3>
                  </>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
      ))}
   
    </div>
  </div>
  );
};
{/* <div className="col-3 mb-2 text-center">
                                <h2 className="fw-bold font-26">6</h2>
                                <h3 className="fw-medium font-18 mt-2">Avg. Base Line</h3>
                            </div>
                            <div className="col-3 mb-2 text-center">
                                <h2 className="fw-bold font-26">9</h2>
                                <h3 className="fw-medium font-18 mt-2">Avg. End Line</h3>
                            </div>
                            <div className="col-3 mb-2 text-center">
                                <h2 className="fw-bold font-26">3</h2>
                                <h3 className="fw-medium font-18 mt-2">Avg. Attempts</h3>
                            </div>
                            <div className="col-3 mb-2 text-center">
                                <h2 className="fw-bold font-26">88%</h2>
                                <h3 className="fw-medium font-18 mt-2">Change</h3>
                            </div> */}

export default CoursePerformance;