import React, { useState, useRef, useEffect } from 'react';
import './inviteToCourse.css';
import apiService from '../../../../services/apiService';
import Modal from 'react-modal';
import CustomToast from '../../../Toast';

const InviteCourseForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [designation, setDesignation] = useState('');
    const [department, setDepartment] = useState('');
    const [entity, setEntity] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [userData, setUserData] = useState();
    const [userLocation, setUserLocation] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const inputRef = useRef(null);


    useEffect(()=>{
      let userDetail = sessionStorage.getItem("userDetail")
      if(userDetail) {
          userDetail = JSON.parse(userDetail)
          setUserData(userDetail)
      }
      fetchOrgEntities(userDetail)
    }, [])

    const fetchOrgEntities = (userDetail) => {
      const token = apiService.getAuthToken()
      const org_id = localStorage.getItem('ORGID')
      apiService.getv1(`org-enitity/${org_id}`, token).then(
          (response) => { 
            setOptions(response.data)
          },
          (error) => {
          console.error("Error fetching data", error);
          CustomToast.error('Error while fetching org entity')
          }
      );
    };

    useEffect(() => {
      if (options && options.length > 0) {
          // Set the initial value to the first course in the list
          setSelectedValue(options[0].org_entity);
      }
  }, [options]);
  
    const handleInputChange = (e) => {
      setSelectedValue(e.target.value);
    };
  
    // const handleKeyDown = (e) => {
    //   if (e.key === 'Enter') {
    //     addOption();
    //   }
    // };
  
    // const addOption = () => {
    //   if (selectedValue.trim() !== '') {
    //     setOptions((prevOptions) => [...prevOptions, selectedValue]);
    //     setSelectedValue('');
    //     console.log('Selected Value:', selectedValue);
    //   }
    // };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        validateEmail(newEmail);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };  
    
    const validateEmail = (email) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailPattern.test(email);
      if (!isValid) {
        setEmailError('Please enter a valid email address.');
      } else {
        setEmailError('');
      }
      return isValid
    };
      
    const handlePhoneChange = (e) => {
      const newPhoneNumber = e.target.value;
      setPhone(newPhoneNumber);
      validatePhone(newPhoneNumber);
    };
      
    const validatePhone = (phone) => {
      const phonePattern = /^[0-9]{10}$/;
      const isValid = phonePattern.test(phone);
      if (!isValid) {
        setPhoneError('Please enter a valid 10-digit phone number.');
      } else {
        setPhoneError('');
      }
      return isValid
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const resetForm = () => {
      setName('');
      setEmail('');
      setPhone('');
      setDesignation('');
      setDepartment('');
      setUserLocation('');
    };

    const fetchData = async () => {

      if(email && !validateEmail(email)) {
        return
      }
      if (phone && !validatePhone(phone)) {
          return;
      }
      let courseData =localStorage.getItem("selectedCourseObj")
      courseData = JSON.parse(courseData)
      let orgData =localStorage.getItem("orgData")
      orgData = JSON.parse(orgData)
      let data = {
        "orgid": orgData.org_id,
        "org_course_id":courseData?.org_course_id,
        "course_id": courseData?.course_id,
        "org_name": orgData?.org_name.trim(),
        "org_entity": selectedValue || "NA",
        "users": [
          {
            "fullname": name,
            "email": email,
            "mobile": phone,
            "designation": designation,
            "department": department,
            "location": userLocation.trim(),
          }
        ]
      }
      try {
        const token = apiService.getAuthToken()
        const result = await apiService.post('send-and-assign-course', data, token);
        if (result && result.status === "success") {
          CustomToast.success('Invitation sent successfully')
          resetForm()
         // openModal()
        }
      } catch (error) {
        if(error && error.message && error.message.includes('Course invite already sent.')) {
          CustomToast.info('The invitation was not sent as the user has already accepted it.')
        } else if(error && error.message && error.message.includes('Not enough licenses available')) {
          CustomToast.info('Insufficient licenses')
        }
      }
    };

    return (
        <div className='course-invite-form'>
            <form onSubmit={handleSubmit}>
            {options && options.length > 0 ? <div className="form-group mb-3">
              <label>Entity* {console.log(options)}</label>
                <select 
                className='form-select position-relative'
                value={selectedValue.org_id}
                onChange={handleInputChange}
                required
                >
                {options.map((option) => (
                  <option key={option.org_id} value={option.org_entity}>
                    {option.org_entity}
                  </option>
                ))}
                </select>
                {/* <input
                  type="text"
                  name='selectedValue'
                  value={selectedValue || ''}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  className='form-control'
                  placeholder="Add option if not available in list"
                  ref={inputRef}
                /> */}
            </div> : null}
                    <div className="form-group mb-3">
                        <label>Name*</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='Name'
                            value={name}
                            name="name"
                            onChange={(e) => setName(e.target.value)}
                        required/>
                    </div>
                    <div className="form-group mb-3">
                        <label>Email Id*</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder='Org. Email ID'
                            value={email}
                            name="email"
                            onChange={handleEmailChange}
                            required />  
                            {emailError && <p className="error">{emailError}</p>}
                    </div>
                    <div className="form-group mb-3">
                        <label>Phone Number</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='xxxxx xxxxx'
                            value={phone}
                            name="phone"
                            onChange={handlePhoneChange} 
                             />
                            {phoneError && <p className="error">{phoneError}</p>}
                    </div>
                    <div className="form-group mb-3">
                        <label>Designation </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='e.g. Manager'
                            value={designation}
                            name="designation"
                            onChange={(e) => setDesignation(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label>Department </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='e.g. IT'
                            value={department}
                            name="department"
                            onChange={(e) => setDepartment(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-4 location">
                    <label className='mb-2'>Location</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='e.g. Pune'
                        value={userLocation}
                        name="userLocation"
                        onChange={(e) => setUserLocation(e.target.value)} 
                    />
                </div>
                    <button type='submit' className='btn btn-primary w-100'onClick={fetchData}>Send Invite</button>
            </form>
          {/* <button className="btn btn-primary" onClick={openModal}>click here </button> */}
         
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Modal"
          className='Modal course-model bg-white p-3'
        > {/*<button type="button" className="close text-white m-2" onClick={closeModal}>×</button>*/}

          <div class="py-4 px-5 w-100 text-center"><p class="fw-bold font-18 mb-2">Invitation sent successfully.</p>
          <button onClick={closeModal} className='btn btn-md btn-primary mt-4 py-2'>Ok</button>
          </div>
        </Modal>
        </div>
        
    )

}

export default InviteCourseForm;